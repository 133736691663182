@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(poppins-regular.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(poppins-bold.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
        U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@media (width < 660px) {
    html {
        font-size: 0.75rem;
    }

    .mongolfiere::before {
        visibility: hidden;
    }
}

* {
    box-sizing: border-box;
}

p {
    line-height: 1.6;
}

a {
    display: inline-block;
}

html,
body {
    margin: 0;
    padding: 0;
    --text-color: #151e72;
    --accent-sea-blue-light: #eefcfd;
    --accent-sea-blue: #52dde7;
    --accent-sky-blue: #81eac7;
    --accent-sea-green: #50d0bc;
    --accent-sea-text-color: #151e72;
    --accent-dark-blue: #151e72;
    --accent-dark-blue-text-color: white;
    --accent-lavender: #8f7cea;
    --accent-lavender-text-color: white;
    --accent-lavender-light: #efefff;
    --accent-orange-very-light: #feefec;
    --accent-orange-light: #ffbb54;
    --accent-orange-light-text-color: white;
    --accent-orange: #f86041;
    --accent-yellow: #f9cd16;
    --max-width: 1200px;
    --accent-grey-0: #f8f8ff;
    --accent-grey-1: #f7f6fe;
    --accent-grey-2: #dadaf0;
    --accent-purple: #8f7cea;
    --gradient-sea-sky: linear-gradient(var(--accent-sea-blue), var(--accent-sky-blue));
    --box-shadow: 0 0 10px #00000035;
    color: var(--text-color);
}

#root {
    font-family: 'Poppins', sans-serif;
}

a {
    color: var(--text-color);
}

@keyframes fly {
    from {
        bottom: -10%;
    }
    to {
        bottom: 110%;
    }
}

@keyframes swing {
    from {
        transform: rotate(-10deg);
    }
    to {
        transform: rotate(10deg);
    }
}
